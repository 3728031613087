
import contentTypeBuilder from '../../../node_modules/@strapi/strapi/node_modules/@strapi/plugin-content-type-builder/strapi-admin.js';
import email from '../../../node_modules/@strapi/plugin-email/strapi-admin.js';
import upload from '../../../node_modules/@strapi/strapi/node_modules/@strapi/plugin-upload/strapi-admin.js';
import graphql from '../../../node_modules/@strapi/plugin-graphql/strapi-admin.js';
import adressUpload from '../../../src/plugins/adress-upload/strapi-admin.js';
import usersPermissions from '../../../node_modules/@strapi/plugin-users-permissions/strapi-admin.js';
import entityRelationshipChart from '../../../node_modules/strapi-plugin-entity-relationship-chart/strapi-admin.js';
import publisher from '../../../node_modules/strapi-plugin-publisher/strapi-admin.js';
import sentry from '../../../node_modules/@strapi/plugin-sentry/strapi-admin.js';
import deepl from '../../../node_modules/strapi-plugin-deepl/strapi-admin.js';
import i18N from '../../../node_modules/@strapi/plugin-i18n/strapi-admin.js';
import duplicateButton from '../../../node_modules/strapi-plugin-duplicate-button/strapi-admin.js';


const plugins = {
  'content-type-builder': contentTypeBuilder,
  'email': email,
  'upload': upload,
  'graphql': graphql,
  'adress-upload': adressUpload,
  'users-permissions': usersPermissions,
  'entity-relationship-chart': entityRelationshipChart,
  'publisher': publisher,
  'sentry': sentry,
  'deepl': deepl,
  'i18n': i18N,
  'duplicate-button': duplicateButton,
};

export default plugins;
